import * as actionTypes from './actionTypes';
import queryString from "query-string";
import cropImage from "../../helpes/cropImage";

const axios = require("axios");

export const updateDesignerStatus = () => {
    return {
        type: actionTypes.UPDATE_DESIGNER_STATUS
    }
};

export const setDesignerMode = (mode) => {
    return {
        type: actionTypes.SET_DESIGNER_MODE,
        mode: mode
    }
};

export const setImgStatusError = (data) => {
    return {
        type: actionTypes.SET_IMG_STATUS_ERROR,
        noImgLoadError: data.noImgLoadError
    }
};

export const deleteSelectedElement = (data) => {

    return {
        type: actionTypes.DELETE_SELECTED_ELEMENT,
        element: data.element,
        activePage: data.activePage,
    }
};

export const setNewElementIndex = (data) => {
    return {
        type: actionTypes.SET_NEW_ELEMENT_INDEX,
        itemName: data.itemName,
        oldIndex: data.oldIndex,
        newIndex: data.newIndex,
    }
};

export const setPreviewUpdateStatus = (data) => {
    return {
        type: actionTypes.PREVIEW_UPDATE_STATUS,
        previewShouldBeChanged: data?.previewShouldBeChanged,
    }
};

export const editElementAttrs = (data) => {
    // TODO showWarning
    // function showWarning(e) {
    //     const text = 'This page is asking you to confirm that you want to leave - data you have entered may not be saved'
    //     e.returnValue = text;
    //     return text;
    // }
    // if(parseInt(queryString.parse(window.location.search).robot) !== 1){
    //     window.onbeforeunload = showWarning;
    // }
    

    return {
        type: actionTypes.EDIT_ELEMENT_ATTRS,
        elementId: data.elementId,
        newText: data.newText,
        smallBackground_image: data.smallBackground_image,
        isThisElemEditedByUser: data.isThisElemEditedByUser,
        bgColor: data.bgColor,
        historyMode: data.historyMode,
        imageWithoutBg: data.imageWithoutBg,
        hasTemplateChanges: data.hasTemplateChanges,
        historyStep: data.historyStep,
        faceId: data.faceId,
        fontStrokeWidth: data.fontStrokeWidth,
        colorFontStroke: data.colorFontStroke,
        index: data.index,
        textLimit: data.textLimit,
        textMaxWidth: data.textMaxWidth,
        letterSpacing: data.letterSpacing,
        pages: data.pages,
        element: data.element,
        activePage: data.activePage,
        color: data.color,
        flipY: data.flipY,
        flipX: data.flipX,
        fontSize: data.fontSize,
        fontStyle: data.fontStyle,
        fontFamily: data.fontFamily,
        align: data.align,
        rotation: data.rotation,
        imageCrop: data.imageCrop,
        newImgData: data.newImgData,
        imageUrl: data.imageUrl,
        transformable: data.transformable,
        user_textTransform: data.user_textTransform,
        user_transformable: data.user_transformable,
        isGlobalElementUrl: data.isGlobalElementUrl,
        user_changeable: data.user_changeable,
        isCutoutPro: data.isCutoutPro,
        isCutoutProBg: data.isCutoutProBg,
        user_removeFromWorkspace: data.user_removeFromWorkspace,
        layer_allocation_data: data.layer_allocation_data,
        cartoonTypeValue: data.cartoonTypeValue,
        user_quickEdit: data.user_quickEdit,
        user_removeFromPreview: data.user_removeFromPreview,
        user_removeFromPrint: data.user_removeFromPrint,
        user_adminAccessOnly: data.user_adminAccessOnly,
        changeable: data.changeable,
        quickEdit: data.quickEdit,
        scaleX: data.scaleX,
        scaleY: data.scaleY,
        x: data.x,
        y: data.y,
        store_view_values: data.store_view_values,
        quick_edit_labels: data.quick_edit_labels,
        quick_edit_color: data.quick_edit_color,
        quick_edit_font: data.quick_edit_font,
        quick_edit_stroke: data.quick_edit_stroke,
        quick_edit_strokeColor: data.quick_edit_strokeColor,
        width: data.width,
        height: data.height,
        heightDiff: data.heightDiff,
        widthDiff: data.widthDiff,
        shadowEnabled: data.shadowEnabled,
        elementRepeat: data.elementRepeat,
        elementRepeatOval: data.elementRepeatOval,
        shadowColor: data.shadowColor,
        unselectable: data.unselectable,
        user_foreground: data.user_foreground,
        user_isShapeHeart: data.user_isShapeHeart,
        deletable: data.deletable,
        keepRatio: data.keepRatio,
    }
};

export const getDesignerMode = () => {
    return {
        type: actionTypes.GET_DESIGNER_MODE
    }
};

export const setItemDownloadingStatus = (data) => {
    return {
        type: actionTypes.IS_ITEM_DOWNLOADING,
        isItemDownloading: data.isItemDownloading
    }
};

export const setStage = (stage) => {
    return {
        type: actionTypes.SET_STAGE,
        stage: stage
    }
};

export const holdUrlData = (data) => {
    return {
        type: actionTypes.HOLD_URL_IN_POPUP,
        HoldURl: data?.HoldURl,
    }
};

export const clickedOnImageCrop = (data) => {
    return {
        type: actionTypes.IS_CLICKED_CROP_IMAGE,
        isClickedOnImageCrop: data?.isClickedOnImageCrop,
    }
};

export const holdBackgoundRemovelImage = (data) => {
    return {
        type: actionTypes.HOLD_BACKGROUND_REMOVE_IMG,
        holdBackgoundRemovelImageData: data?.holdBackgoundRemovelImageData,
    }
};

export const initDesigner = (initParams) => {
    return dispatch => {
        dispatch(loadDesignInfo(initParams));
    }
};

let commonProperties = function (element, newTemplateRatio = {
    widthRatio: 1,
    heightRatio: 1
}, maskWidth, maskHeight, isMergedTemplate, originalTemplateHeight, originalTemplateWidth, isRobot) {


    let scaleX = (element.scale_x ?? element.scaleX ?? 1);
    let scaleY = (element.scale_y ?? element.scaleY ?? 1);

    if ((element.background || element.name === 'Background2') && isRobot) {
        scaleX = scaleX * (newTemplateRatio?.widthRatio ?? 1)
        scaleY = scaleY * (newTemplateRatio?.heightRatio ?? 1)
    } else if ((element.background || element.name === 'Background2') && !isRobot) {
        scaleX = scaleX / (newTemplateRatio?.widthRatio ?? 1)
        scaleY = scaleY / (newTemplateRatio?.heightRatio ?? 1)
    }
    // let elementx = "";
    // if(element.x<296){
    //     elementx = element.x - (element.fontSize);
    // }else{
    //     elementx = element.x + (element.fontSize);
    // }
    // if(element.x>=293 && element.x<=298 ){
    //     elementx = element.x;
    // }
    // elementx = 296;
    return {
        type: element.type,
        unselectable: element.unselectable ?? false,
        user_foreground: element.user_foreground ?? false,
        keepRatio: element.keepRatio ?? true,
        deletable: element.deletable ?? true,
        // x: element.x,
        // y: element.y,
        x: (element.background || element.name === 'Background2' || isRobot) ? element.x : element.x + (newTemplateRatio.x ?? 0),
        y: (element.background || element.name === 'Background2' || isRobot) ? element.y : element.y + (newTemplateRatio.y ?? 0),
        name: element.name,
        key: element.name,
        rotation: element.rotation ?? 0,
        draggable: element.draggable,
        scaleX,
        scaleY,
        editable: element.editable ?? true,
        admin: element.admin ?? 1,
        changeable: element.changeable ?? 0,
        transformable: element.transformable,
        quickEdit: element.quickEdit,
        user_transformable: element.user_transformable ?? false,
        isGlobalElementUrl: element.isGlobalElementUrl ?? false,
        isThisElemEditedByUser: false,
        user_changeable: element.user_changeable ?? false,
        isCutoutPro: element.isCutoutPro ?? false,
        isCutoutProBg: element.isCutoutProBg ?? false,
        user_removeFromWorkspace: element.user_removeFromWorkspace ?? false,
        layer_allocation_data: element.layer_allocation_data ?? '',
        cartoonTypeValue: element.cartoonTypeValue ?? 0,
        user_quickEdit: element.user_quickEdit ?? false,
        user_removeFromPreview: element.user_removeFromPreview ?? false,
        user_removeFromPrint: element.user_removeFromPrint ?? false,
        user_adminAccessOnly: element.user_adminAccessOnly ?? false,
        store_view_values: element.store_view_values ?? false,
        quick_edit_labels: element.quick_edit_labels ?? false,
        quick_edit_color: element.quick_edit_color || false,
        quick_edit_font: element.quick_edit_font ?? false,
        quick_edit_stroke: element.quick_edit_stroke ?? false,
        quick_edit_strokeColor: element.quick_edit_strokeColor ?? false,
        changePosition: element.changePosition || false,
    }
};


export function calculateTargetDimensions(sw, sh, tw, th) {
    let sRatio = sw / sh, x, y;
    let scale;
    sw = Math.min(sw, tw);
    sh = sw / sRatio;

    sh = Math.min(sh, th);
    sw = sh * sRatio;
    scale = (sw / tw) * (sh / th)

    x = ((tw - sw) / scale) / 2;
    y = ((th - sh) / scale) / 2;


    if (tw === th && sh < sw) {
        scale = 1;
    } else {
        if (x) {
            // console.log(1);
            if (sh > sw) {
                // console.log(1.1);
                scale = sh / tw;
                x = ((tw - sw) / scale) / 2
            } else {
                // console.log(1.2);
                x = ((((592 / scale)) - 592) / 2)
            }
        } else {
            // console.log(2)
            y = ((((592 / scale)) - 592) / 2)
        }
    }

    if (sh < sw && th > tw) {
        // console.log(3)
        scale = tw / th;
        y = (th - sh) / scale / 2
        x = 0
    }

    if (sh > sw && th > tw) {
        // console.log(4)
        scale = 1
        y = 0
        x = (tw - sw) / 2;
    }

    if (sw === tw && th > sh) {
        // console.log(5)
        x = ((th - sh) / scale) / 2
        y = 0

        if (sw > sh && tw < th) {
            // console.log(5.1)
            x = 0;
            y = ((th - sh) / scale) / 2
        } else {
            scale = sh / th;
            x = 0;
            y = ((th - sh) / scale) / 2
        }

        if (sw > sh && tw > th) {
            // console.log(5.3);
            scale = 1
            x = 0;
            y = ((th - sh) / scale) / 2
        }
    }


    if (sw > sh && tw === th) {
        // console.log(6)
        y = ((th - sh) / scale) / 2
        x = 0
    }

    // console.log(sw , sh, tw , th)

    return {
        scale: scale, x: x, y: y
    }
}


export const loadDesignInfo = (initParams) => {
    return dispatch => {
        /* Check init params */
        let pages = [];
        let storeViews = [];
        let activeLayer = 0;

        if (initParams.layerId === undefined) {
            /* Set default page */
            let page = {
                width: 2000,
                height: 2000,
                canvasElements: [],
                renderers: [],
                googleFonts: []
            };

            pages.push(page);
        } else {
            /* Load data about design */
            axios({
                method: 'get',
                url: initParams.magentoUrl + 'productsdesigner/config/read/id_design/' + initParams.designId + '/id_product/' + initParams.productId + '/id_store/' + initParams.storeId + '/id_session/' + initParams.sessionId + '/id_template/' + initParams.layerId + '/id_update/' + initParams.updateId + '/id_selection/' + initParams.selectionId + '/robot/' + initParams.robot
                //,
                //headers: {'Authorization': 'Basic '+btoa('mhdirekt:79evb91nm6')},
                //headers: {"Access-Control-Allow-Origin": "true", "crossorigin":true, 'authorization': 'Basic '+btoa('mhdirekt:79evb91nm6')}
            }).then(function (response) {

                // Process response
                // console.log("API", initParams.magentoUrl + 'productsdesigner/config/read/id_design/' + initParams.designId + '/id_product/' + initParams.productId + '/id_store/' + initParams.storeId + '/id_session/' + initParams.sessionId + '/id_template/' + initParams.layerId + '/id_update/' + initParams.updateId + '/id_selection/' + initParams.selectionId + '/robot/' + initParams.robot)

                let configuration = JSON.parse(response.data.configuration);
                console.log("configuration from API--->>>>>>>> ", configuration)
                // localStorage.setItem("configuration",JSON.stringify(configuration))
                // console.log("localStorage.getItem('fload'): ",localStorage.getItem('fload'))
                setTimeout(() => {
                    if (localStorage.getItem('fload') != 1) {
                        window.location.reload(false);
                        localStorage.setItem('fload', 1)
                    }
                }, 1000);

                localStorage.setItem('rotationingstate', false)
                /* Check if there is any page connect with design */
                let newDesignTemplate;
                let newMaskTemplate;
                let newTemplateRatio = { widthRatio: 1, heightRatio: 1 };
                let originalTemplateWidth;
                let originalTemplateHeight;
                let maskWidth;
                let maskHeight;
                let isMergedTemplate;
                // console.log("Layers:->", configuration.layers)
                if (configuration.layers.length > 0) {
                    /* Process pages */
                    for (let i = 0, length = configuration.layers.length; i < length; i++) {
                        /* Read page data */

                        let layer = configuration.layers[i];
                        isMergedTemplate = JSON.parse(layer.configuration).isMergedTemplate;
                        // console.log("JSON.parse(layer.configuration)", JSON.parse(layer.configuration))
                        newDesignTemplate = queryString.parse(window.location.search).disablemask === "true" || isMergedTemplate || (initParams.robot === 1) ? null : configuration?.new_data?.product_templates?.design;
                        newMaskTemplate = newDesignTemplate ? (queryString.parse(window.location.search).disablemask === "true" || isMergedTemplate || (initParams.robot === 1) ? null : configuration?.new_data?.product_templates?.base) : null;
                        //  console.log("----newDesignTemplate",newDesignTemplate)
                        // console.log("newMaskTemplate",newMaskTemplate)
                        if ((newDesignTemplate && newMaskTemplate) && !(initParams.robot === 1)) {

                            layer = newDesignTemplate;

                            layer.layerMaskWidth = newMaskTemplate?.width ?? newDesignTemplate.width;
                            layer.layerMaskHeight = newMaskTemplate?.height ?? newDesignTemplate.height;

                            //newTemplateRatio
                            originalTemplateWidth = layer.width;
                            originalTemplateHeight = layer.height;
                            maskWidth = layer?.layerMaskWidth ?? layer.width;
                            maskHeight = layer.layerMaskHeight ?? layer.height;

                            // console.log("originalTemplateWidth", originalTemplateWidth)
                            // console.log("originalTemplateHeight", originalTemplateHeight)
                            // console.log("maskWidth", maskWidth)
                            // console.log("maskHeight", maskHeight)

                            let areDifferentSizes = originalTemplateWidth !== maskWidth || originalTemplateHeight !== maskHeight;
                            let areDifferentRatio = (originalTemplateWidth * 1 / originalTemplateHeight * 1).toFixed(2) !== (maskWidth * 1 / maskHeight * 1).toFixed(2);


                            if (originalTemplateWidth && originalTemplateHeight && maskWidth && maskHeight) {
                                if (queryString.parse(window.location.search).disablemask !== "true" && isMergedTemplate != true && !(initParams.robot == 1)) {
                                    let scaledMaskWidth = parseFloat(isMergedTemplate ? JSON.parse(layer.configuration).width : (layer?.layerMaskWidth ?? layer.width)),
                                        scaledMaskHeight = parseFloat(isMergedTemplate ? JSON.parse(layer.configuration).height : (layer?.layerMaskHeight ?? layer.height));

                                    let stagSize = 592;
                                    if (scaledMaskWidth > scaledMaskHeight) {
                                        let newHeight = stagSize / (scaledMaskWidth / scaledMaskHeight);
                                        // console.log('scaledMaskWidth', stagSize);
                                        // console.log('scaledMaskHeight', newHeight);
                                        scaledMaskWidth = stagSize;
                                        scaledMaskHeight = newHeight
                                    } else if (scaledMaskHeight > scaledMaskWidth) {
                                        let newWidth = stagSize / (scaledMaskHeight / scaledMaskWidth);
                                        // console.log('scaledMaskWidth', newWidth);
                                        // console.log('scaledMaskHeight', stagSize);
                                        scaledMaskWidth = newWidth;
                                        scaledMaskHeight = stagSize
                                    } else {
                                        // console.log('scaledMaskWidth', stagSize);
                                        // console.log('scaledMaskHeight', stagSize);
                                        scaledMaskWidth = stagSize;
                                        scaledMaskHeight = stagSize
                                    }
                                    let scaledDesignWidth = parseFloat(layer.width);
                                    let scaledDesignHeight = parseFloat(layer.height);

                                    // stagSize = Math.min(scaledMaskWidth, scaledMaskHeight)
                                    if (scaledDesignWidth > scaledDesignHeight) {
                                        let newHeight = stagSize / (scaledDesignWidth / scaledDesignHeight);
                                        // console.log('scaledDesignWidth', stagSize);
                                        // console.log('scaledDesignHeight', newHeight);
                                        scaledDesignWidth = stagSize;
                                        scaledDesignHeight = newHeight;
                                    } else if (scaledDesignHeight > scaledDesignWidth) {
                                        let newWidth = stagSize / (scaledDesignHeight / scaledDesignWidth);
                                        // console.log('scaledDesignWidth', newWidth);
                                        // console.log('scaledDesignHeight', stagSize);
                                        scaledDesignWidth = newWidth;
                                        scaledDesignHeight = stagSize;
                                    } else {
                                        // console.log('scaledDesignWidth', stagSize);
                                        // console.log('scaledDesignHeight', stagSize);
                                        scaledDesignWidth = stagSize;
                                        scaledDesignHeight = stagSize;
                                    }

                                    originalTemplateWidth = scaledDesignWidth;
                                    originalTemplateHeight = scaledDesignHeight;
                                    maskWidth = scaledMaskWidth;
                                    maskHeight = scaledMaskHeight;


                                    let widthRatio = 1;
                                    let heightRatio = 1;


                                    if (areDifferentSizes && areDifferentRatio) {
                                        widthRatio = heightRatio = calculateTargetDimensions(scaledDesignWidth, scaledDesignHeight, scaledMaskWidth, scaledMaskHeight).scale
                                        // console.log(scaledDesignWidth, scaledDesignHeight, scaledMaskWidth, scaledMaskHeight)
                                        newTemplateRatio = {
                                            widthRatio,
                                            heightRatio,
                                            x: calculateTargetDimensions(scaledDesignWidth, scaledDesignHeight, scaledMaskWidth, scaledMaskHeight).x,
                                            y: calculateTargetDimensions(scaledDesignWidth, scaledDesignHeight, scaledMaskWidth, scaledMaskHeight).y
                                        }
                                    }

                                }
                                // console.log("newTemplateRatio", newTemplateRatio)
                            }
                        }
                        /* Set store views */
                        storeViews = [{ id: "0", store_name: 'Default store view:' }];
                        for (let store_id in layer.store_views) {
                            storeViews.push({ id: store_id, store_name: layer.store_views[store_id] });
                        }
                        /* Set first active layer */
                        activeLayer = layer.layer_id;
                        let toolImagesAllowed, toolShapesAllowed, toolEmojiAllowed, toolTextAllowed,
                            propertyTextStrokeAllowed, propertyTextStrokeColorAllowed, propertyTextShadowColorAllowed,
                            propertyTextShadowAllowed, propertyTextColorAllowed, propertyTextShowAllowed,
                            propertyTextFontAllowed, propertyFaceCropSizeAllowed, propertyFaceCropZoomAllowed,
                            propertyFaceCropRotateAllowed, multiface_elements_admin_only, png_output, layerDatas, only_fulleditor, commonEditorForAll, commonChangeCurrentImage,
                            globalWidth, multipartTemplate, onlyEmbroideryColors;

                        if (layer.configuration && JSON.parse(layer.configuration)) {
                            // console.log(layer.configuration)
                            let configuration = JSON.parse(layer.configuration);
                            layerDatas = JSON.parse(layer.configuration);
                            toolImagesAllowed = configuration.toolImagesAllowed;
                            toolShapesAllowed = configuration.toolShapesAllowed;
                            toolEmojiAllowed = configuration.toolEmojiAllowed;
                            toolTextAllowed = configuration.toolTextAllowed;
                            propertyTextStrokeAllowed = configuration.propertyTextStrokeAllowed;
                            propertyTextStrokeColorAllowed = configuration.propertyTextStrokeColorAllowed;
                            propertyTextColorAllowed = configuration.propertyTextColorAllowed;
                            propertyTextShowAllowed = configuration.propertyTextShowAllowed;
                            propertyTextFontAllowed = configuration.propertyTextFontAllowed;
                            propertyFaceCropSizeAllowed = configuration.propertyFaceCropSizeAllowed;
                            propertyFaceCropZoomAllowed = configuration.propertyFaceCropZoomAllowed;
                            propertyFaceCropRotateAllowed = configuration.propertyFaceCropRotateAllowed;
                            propertyTextShadowColorAllowed = configuration.propertyTextShadowColorAllowed;
                            propertyTextShadowAllowed = configuration.propertyTextShadowAllowed;

                        }

                        newTemplateRatio = initParams.robot == 1 ? {
                            widthRatio: (layer?.width_ratio ?? 1) != 0 ? (layer?.width_ratio ?? 1) : 1,
                            heightRatio: (layer?.height_ratio ?? 1) != 0 ? (layer?.height_ratio ?? 1) : 1,
                        } : newTemplateRatio


                        // console.log("configuration.layers[i]", configuration.layers[i])
                        // console.log("newTemplateRatio", newTemplateRatio)
                        multiface_elements_admin_only = configuration.layers[i].multiface_elements_admin_only ?? 0
                        png_output = configuration.layers[i].png_output ?? 0
                        // console.log("adminONLY:",multiface_elements_admin_only )
                        only_fulleditor = configuration.layers[i].only_fulleditor ?? 0
                        // console.log('straightlined--****------>',configuration.layers[i].straightlined)
                        // console.log(initParams.mode,'initParams---)))))------',initParams.robot)
                        if (initParams.mode === 'PRODUCTION' && initParams.robot === 0) {
                            commonEditorForAll = configuration.layers[i].straightlined ? configuration.layers[i].straightlined : 0;
                        } else {
                            commonEditorForAll = 0;
                        }

                        // Set Multipart template
                        multipartTemplate = configuration.layers[i]?.multipart_template ? configuration.layers[i]?.multipart_template : 0;
                        onlyEmbroideryColors = configuration.layers[i]?.only_embroidery_colors ? configuration.layers[i]?.only_embroidery_colors : 0;

                        commonChangeCurrentImage = 0;
                        globalWidth = 296;
                        /* Prepare page data */
                        let canvasMaskElements = newMaskTemplate ? JSON.parse(newMaskTemplate?.configuration)?.canvasMaskElements : ((JSON.parse(layer?.configuration)?.canvasMaskElements ?? JSON.parse(configuration?.layers[i].configuration)?.canvasMaskElements) ?? [])
                        localStorage.setItem("configuration", canvasMaskElements)
                        let renderers = configuration?.new_data?.product_templates?.base?.renderers?.length > 0 ? configuration?.new_data?.product_templates?.base?.renderers : (configuration?.new_data?.product_templates?.design?.renderers.length > 0 ? configuration?.new_data?.product_templates?.design?.renderers : configuration.layers[i].renderers)

                        // console.log(configuration?.new_data?.product_templates?.base?.renderers, configuration?.new_data?.product_templates?.design?.renderers)

                        let googleFonts = configuration?.new_data?.product_templates?.design ? configuration?.new_data?.product_templates?.design?.google_fonts : layer?.google_fonts
                        let customFonts = configuration?.new_data?.product_templates?.design ? configuration?.new_data?.product_templates?.design?.custom_fonts : layer?.custom_fonts
                        // let baseTemplateRenderers = configuration?.new_data?.product_templates?.base?.renderers;
                        //
                        // let designTemplateRenderers = configuration?.new_data?.product_templates?.design?.renderers;
                        // let renderers = designTemplateRenderers.length > 0 ? designTemplateRenderers : (baseTemplateRenderers.length > 0 ? baseTemplateRenderers : configuration.layers[0].renderers)

                        // console.log("renderers onload", renderers);

                        // let obj = configuration?.new_data?.product_templates?.design?.google_fonts_files['Playfair Display'];
                        // let arrX = {'Playfair Display':[]};
                        // for(var key in obj)
                        // {
                        //     arrX['Playfair Display'][key] = obj[key]; 
                        // }
                        // // console.log("New FONTS: ", arrX)
                        // let googleFonts = arrX;


                        let page = {
                            baseLayer_id: configuration?.new_data?.product_templates?.base?.layer_id,
                            width: isMergedTemplate ? JSON.parse(layer.configuration).width : (layer?.layerMaskWidth ?? layer.width),
                            height: isMergedTemplate ? JSON.parse(layer.configuration).height : (layer?.layerMaskHeight ?? layer.height),
                            canvasElements: [],
                            renderers,
                            backgrounds: layer.backgrounds,
                            designs: layer.designs,
                            colors: layer.colors,
                            googleFonts,
                            customFonts,
                            canvasMaskElements: initParams.robot === 1 ? [] : (canvasMaskElements ?? []),
                            borderLimit: JSON.parse(layer?.configuration)?.borderLimit,
                            toolImagesAllowed,
                            toolShapesAllowed,
                            toolEmojiAllowed,
                            toolTextAllowed,
                            propertyTextStrokeAllowed,
                            propertyTextStrokeColorAllowed,
                            propertyTextShadowColorAllowed,
                            propertyTextShadowAllowed,
                            propertyTextColorAllowed,
                            propertyTextShowAllowed,
                            propertyTextFontAllowed,
                            propertyFaceCropSizeAllowed,
                            propertyFaceCropZoomAllowed,
                            propertyFaceCropRotateAllowed,
                            multiface_elements_admin_only,
                            png_output,
                            layerDatas,
                            only_fulleditor,
                            commonEditorForAll,
                            commonChangeCurrentImage,
                            globalWidth,
                            newDesignTemplate,
                            newTemplateRatio,
                            isMergedTemplate: isMergedTemplate || (newMaskTemplate && newDesignTemplate),
                            multipartTemplate,
                            onlyEmbroideryColors
                        };


                        /* Prepare canvas elements from background and configuration */
                        let canvasElements = [],
                            backgroundElement = function (layer) {
                                let type, data;
                                if (layer.background_type === "image") {
                                    type = "image";
                                    data = layer.background_image;
                                } else if (layer.background_type === "color") {
                                    type = "square";
                                    data = layer.background_color;
                                } else {
                                    return {};
                                }
                                return {
                                    type: type,
                                    x: 0,
                                    y: 0,
                                    width: layer.width,
                                    height: layer.height,
                                    name: 'background',
                                    key: 'background',
                                    data: data,
                                    background: true,
                                    admin: 1,
                                    changeable: false,
                                    transformable: false,
                                    user_transformable: false,
                                    isGlobalElementUrl: false,
                                    user_changeable: false,
                                    isCutoutPro: false,
                                    isCutoutProBg: false,
                                    user_removeFromWorkspace: false,
                                    layer_allocation_data: '',
                                    cartoonTypeValue: 0,
                                    user_quickEdit: false,
                                    deletable: false,
                                    unselectable: true,
                                    user_removeFromPreview: false,
                                    user_removeFromPrint: false,
                                    user_foreground: false,
                                    user_isShapeHeart: false,
                                    quickEdit: false,
                                    changePosition: false,
                                }
                            };

                        canvasElements.push(backgroundElement(layer));

                        /* Prepare canvas elements from store configuration */
                        if (layer.configuration && layer.configuration !== "null") {
                            let
                                layerConfiguration = JSON.parse(layer.configuration),
                                layerCanvasElements = layerConfiguration.canvasElements;
                            // console.log("layerConfiguration.canvasElements", layerConfiguration.canvasElements)
                            for (let y = 0, length = layerCanvasElements?.length || 0; y < length; y++) {
                                if (layerCanvasElements[y] !== undefined) {
                                    let element = layerCanvasElements[y];
                                    // console.log("element.flipX======>",element)
                                    let quick_edit_labels = [];
                                    if (element.quick_edit_labels !== undefined) {
                                        if (element.quick_edit_labels.length === 0) {
                                            for (let s = 0; s < storeViews.length; s++) {
                                                quick_edit_labels.push({ store_id: storeViews[s].id, label: "" });
                                            }
                                        } else {
                                            quick_edit_labels = element.quick_edit_labels;
                                        }
                                    } else {
                                        for (let s = 0; s < storeViews.length; s++) {
                                            quick_edit_labels.push({ store_id: storeViews[s].id, label: "" });
                                        }
                                    }

                                    switch (element.type) {
                                        case "square":
                                            canvasElements.push({
                                                ...(commonProperties(element, newTemplateRatio, maskWidth, maskHeight, isMergedTemplate, originalTemplateHeight, originalTemplateWidth, initParams.robot === 1)),
                                                width: element.width,
                                                height: element.height,
                                                fill: element.fill,
                                                background: false,
                                                layer_allocation_data: element.layer_allocation_data,
                                                user_removeFromWorkspace: element.user_removeFromWorkspace,
                                                
                                            });
                                            break;
                                        case "circle":
                                            canvasElements.push({
                                                ...(commonProperties(element, newTemplateRatio, maskWidth, maskHeight, isMergedTemplate, originalTemplateHeight, originalTemplateWidth, initParams.robot === 1)),
                                                radius: element.radius * newTemplateRatio,
                                                fill: element.fill,
                                                background: false,
                                                layer_allocation_data: element.layer_allocation_data,
                                                user_removeFromWorkspace: element.user_removeFromWorkspace,
                                            });
                                            break;
                                        case "triangle":
                                            canvasElements.push({
                                                ...(commonProperties(element, newTemplateRatio, maskWidth, maskHeight, isMergedTemplate, originalTemplateHeight, originalTemplateWidth, initParams.robot === 1)),
                                                radius: element.radius * newTemplateRatio,
                                                fill: element.fill,
                                                background: false,
                                                sides: element.sides,
                                                layer_allocation_data: element.layer_allocation_data,
                                                user_removeFromWorkspace: element.user_removeFromWorkspace,
                                            });
                                            break;
                                        case "line":
                                            canvasElements.push({
                                                ...(commonProperties(element, newTemplateRatio, maskWidth, maskHeight, isMergedTemplate, originalTemplateHeight, originalTemplateWidth, initParams.robot === 1)),
                                                fill: element.fill,
                                                background: false,
                                                points: element.points,
                                                layer_allocation_data: element.layer_allocation_data,
                                                user_removeFromWorkspace: element.user_removeFromWorkspace,
                                            });
                                            break;
                                        case "text":
                                            canvasElements.push({
                                                ...(commonProperties(element, newTemplateRatio, maskWidth, maskHeight, isMergedTemplate, originalTemplateHeight, originalTemplateWidth, initParams.robot === 1)),
                                                user_textTransform: element.user_textTransform ?? {
                                                    value: 'none',
                                                    label: 'None'
                                                },
                                                flipX: element.flipX || false,
                                                flipY: element.flipY || false,
                                                letterSpacing: element.letterSpacing || 0,
                                                fontStrokeWidth: element.fontStrokeWidth,
                                                colorFontStroke: element.colorFontStroke,
                                                textLimit: element.textLimit || 0,
                                                textMaxWidth: element.textMaxWidth || 0,
                                                fontStyle: element.fontStyle,
                                                align: element.align,
                                                fill: element.fill,
                                                fontSize: element.fontSize,
                                                fontFamily: element.fontFamily,
                                                data: element.text,
                                                shadowEnabled: element.shadowEnabled,
                                                shadowColor: element.shadowColor,
                                                elementId: element.elementId,
                                                changePosition: element.changePosition || false,
                                                layer_allocation_data: element.layer_allocation_data,
                                                user_removeFromWorkspace: element.user_removeFromWorkspace,
                                            });
                                            break;
                                        case 'image':
                                            if (element.name == 'Background') {
                                                if (initParams.mode === 'PRODUCTION' && initParams.robot === 0) {
                                                    //let parentKeyBackground = "";
                                                    // parentKeyBackground = Object.keys(layer.designs).find((key) => Object.values(layer.designs[key]).indexOf(element.image) > -1);
                                                    // if(parentKeyBackground && layer.designs[parentKeyBackground].small_image){
                                                    //     element.image = layer.designs[parentKeyBackground].small_image;
                                                    // }
                                                    let splitElementImage = element.image.split('/');
                                                    var parentKeyBackgrounds = Object.keys(layer.designs).map(function (key) {
                                                        let splitDesign = layer.designs[key].image.split('/');
                                                        if (splitDesign[splitDesign.length - 1] == splitElementImage[splitElementImage.length - 1]) {
                                                            if (layer.designs[key].small_image && layer.designs[key].image) {
                                                                element.image = layer.designs[key].small_image;
                                                            }
                                                        }
                                                    });
                                                } else if (initParams.mode === 'PRODUCTION' && initParams.robot === 1) {
                                                    let splitElementImage = element.image.split('/');
                                                    var parentKeyBackgrounds = Object.keys(layer.designs).map(function (key) {
                                                        let splitDesign = layer.designs[key].image.split('/');
                                                        if (splitDesign[splitDesign.length - 1] == splitElementImage[splitElementImage.length - 1]) {
                                                            if (layer.designs[key].small_image && layer.designs[key].image) {
                                                                element.image = layer.designs[key].image;
                                                            }
                                                        } else {
                                                            let splitDesignSmall = layer.designs[key].small_image.split('/');
                                                            if (splitDesignSmall[splitDesign.length - 1] == splitElementImage[splitElementImage.length - 1]) {
                                                                if (layer.designs[key].small_image && layer.designs[key].image) {
                                                                    element.image = layer.designs[key].image;
                                                                }
                                                            }
                                                        }
                                                    });
                                                }
                                            }
                                            //console.log(element.image,'====',element.name,'====',layer.designs)
                                            canvasElements.push({
                                                ...(commonProperties(element, newTemplateRatio, maskWidth, maskHeight, isMergedTemplate, originalTemplateHeight, originalTemplateWidth, initParams.robot === 1)),
                                                bgColor: element.bgColor,
                                                width: element.width * (element?.scaleX ?? 1) / (element.background || element.name === 'Background2' ? (newTemplateRatio?.widthRatio ?? 1) : 1),
                                                height: element.height * (element?.scaleY ?? 1) / (element.background || element.name === 'Background2' ? (newTemplateRatio?.heightRatio ?? 1) : 1),
                                                naturalWidth: element.naturalWidth,
                                                naturalHeight: element.naturalHeight,
                                                imageWithoutBg: element.imageWithoutBg,
                                                user_isShapeHeart: element.user_isShapeHeart,
                                                // scaleX: (element.background || element.name === 'Background2' ? 1 : (isMergedTemplate ? 1 : newTemplateRatio.widthRatio)),
                                                // scaleY: (element.background || element.name === 'Background2' ? 1 : (isMergedTemplate ? 1 : newTemplateRatio.widthRatio)),
                                                cropHeight: element.cropHeight || 0,
                                                cropWidth: element.cropWidth || 0,
                                                cropX: element.cropX || 0,
                                                cropY: element.cropY || 0,
                                                newBoxX: element.newBoxX,
                                                newBoxY: element.newBoxY,
                                                newBoxWidth: element.newBoxWidth,
                                                newBoxHeight: element.newBoxHeight,
                                                smallBackground_image: element.smallBackground_image,
                                                heightDiff: element.heightDiff,
                                                widthDiff: element.widthDiff,
                                                data: element.image,
                                                elementRepeat: element.elementRepeat,
                                                elementRepeatOval: element.elementRepeatOval,
                                                faceId: element.faceId,
                                                faceCropped: element.faceCropped,
                                                layer_allocation_data: element.layer_allocation_data,
                                                user_removeFromWorkspace: element.user_removeFromWorkspace,
                                            });
                                            break;
                                        case 'icon':
                                            canvasElements.push({
                                                ...(commonProperties(element, newTemplateRatio, maskWidth, maskHeight, isMergedTemplate, originalTemplateHeight, originalTemplateWidth, initParams.robot === 1)),
                                                width: element.width * (element?.scaleX ?? 1) / (element.background || element.name === 'Background2' ? (newTemplateRatio?.widthRatio ?? 1) : 1),
                                                height: element.height * (element?.scaleY ?? 1) / (element.background || element.name === 'Background2' ? (newTemplateRatio?.heightRatio ?? 1) : 1),
                                                cropHeight: element.cropHeight || 0,
                                                cropWidth: element.cropWidth || 0,
                                                cropX: element.cropX || 0,
                                                cropY: element.cropY || 0,
                                                newBoxX: element.newBoxX,
                                                newBoxY: element.newBoxY,
                                                newBoxWidth: element.newBoxWidth,
                                                newBoxHeight: element.newBoxHeight,
                                                data: element.image,
                                                layer_allocation_data: element.layer_allocation_data,
                                                user_removeFromWorkspace: element.user_removeFromWorkspace,
                                            });
                                            break;
                                        default:
                                            console.warn("First load elements. Some elements have an error.");
                                    }
                                }
                            }
                        }

                        /* Add elements into page object */
                        page.canvasElements = canvasElements;
                        console.log("canvasElements", canvasElements)
                        /* Add page into array */
                        pages.push(page);
                    }
                }
                if (configuration === undefined) {
                    let page = {
                        width: 2000,
                        height: 2000,
                        canvasElements: [],
                        renderers: [],
                        googleFonts: []
                    };

                    pages.push(page);
                }
                // console.log(initParams, pages, activeLayer, storeViews)
                /* Set params to global variables */
                dispatch(loadUploadedImages(initParams, pages, activeLayer, storeViews));
            }).catch((e) => {
                console.log(e);
            });
        }
    }
};

export const loadUploadedImages = (initParams, pages, activeLayer, storeViews) => {
    return dispatch => {
        /* Load images according to mode */

        if (initParams.mode === 'ADMIN' || initParams.robot === 1) {
            let uploadedImages = [];
            let filesDimensionsOriginalKeys;
            /* AJAX call to get images list */

            axios.get(initParams.uploaderUrl + "/visitor/filelist/" + initParams.sessionId).then((response) => {
                /* Final array with all uploaded images */
                filesDimensionsOriginalKeys = Object.keys(response.data.filesDimensionsOriginal);
                for (let i = 0; i < response.data.files.length; i++) {
                    uploadedImages.push({
                        filesDimensions: response.data.filesDimensions[i],
                        filesDimensionsOriginal: response.data.filesDimensionsOriginal[filesDimensionsOriginalKeys[i]],
                        key: uploadedImages.length + 1,
                        url: initParams.uploaderUrl + "/visitor/filename/" + initParams.sessionId + "/thumbs/" + response.data.files[i]
                    });
                }
                // console.log("uploadedImages1", uploadedImages)
                axios.get(initParams.uploaderUrl + "/admin/filelist").then((response) => {
                    /* Final array with all uploaded images */
                    filesDimensionsOriginalKeys = Object.keys(response.data.filesDimensionsOriginal);
                    for (let i = 0; i < response.data.files.length; i++) {
                        uploadedImages.push({
                            filesDimensions: response.data.filesDimensions[i],
                            filesDimensionsOriginal: response.data.filesDimensionsOriginal[filesDimensionsOriginalKeys[i]],
                            key: uploadedImages.length + 1,
                            url: initParams.uploaderUrl + "/uploads/admin/thumbs/" + response.data.files[i]
                        });

                    }
                    // console.log("uploadedImages2", uploadedImages)
                    /* Set uploaded images */
                    dispatch(setInitParams(initParams, pages, uploadedImages, activeLayer, storeViews));
                }).catch(() => {
                    console.log("There was error during getting files!")
                });

                /* Set uploaded images */
                // dispatch(setInitParams(initParams, pages, uploadedImages, activeLayer, storeViews));

            }).catch((error) => {
                console.log(error);
                console.log("There was error during getting files!")
            });

        } else {
            /* AJAX call to get images list */
            axios.get(initParams.uploaderUrl + "/visitor/filelist/" + initParams.sessionId).then((response) => {
                /* Final array with all uploaded images */
                let uploadedImages = [];
                let filesDimensionsOriginalKeys = Object.keys(response.data.filesDimensionsOriginal);

                for (let i = 0; i < response.data.files.length; i++) {
                    uploadedImages.push({
                        filesDimensions: response.data.filesDimensions[i],
                        filesDimensionsOriginal: response.data.filesDimensionsOriginal[filesDimensionsOriginalKeys[i]],
                        key: uploadedImages.length + 1,
                        url: initParams.uploaderUrl + "/visitor/filename/" + initParams.sessionId + "/thumbs/" + response.data.files[i]
                    });
                }

                // console.log(initParams, pages, uploadedImages, activeLayer, storeViews)
                /* Set uploaded images */
                dispatch(setInitParams(initParams, pages, uploadedImages, activeLayer, storeViews));

            }).catch((error) => {
                console.log(error);
                console.log("There was error during getting files!")
            });
        }
    }
};

export const refreshUploadedImages = (params) => {

    return dispatch => {
        /* Load images according to mode */
        if (params?.mode === 'ADMIN' || params?.robot === 1) {
            /* AJAX call to get images list */
            let uploadedImages = [];

            if (params.dataAfterImgAdded === undefined) {

                axios.get(params.uploaderUrl + "/admin/filelist").then((response) => {
                    /* Final array with all uploaded images */

                    let filesDimensionsOriginalKeys = Object.keys(response.data.filesDimensionsOriginal);
                    for (let i = 0; i < response.data.files.length; i++) {
                        uploadedImages.push({
                            filesDimensions: response.data.filesDimensions[i],
                            filesDimensionsOriginal: response.data.filesDimensionsOriginal[filesDimensionsOriginalKeys[i]],
                            key: uploadedImages.length + 1,
                            url: params.uploaderUrl + "/uploads/admin/thumbs/" + response.data.files[i]
                        });
                    }

                    axios.get(params.uploaderUrl + "/visitor/filelist/" + params.sessionId).then((response) => {
                        /* Final array with all uploaded images */

                        let filesDimensionsOriginalKeys = Object.keys(response.data.filesDimensionsOriginal);
                        for (let i = 0; i < response.data.files.length; i++) {
                            uploadedImages.push({
                                filesDimensions: response.data.filesDimensions[i],
                                filesDimensionsOriginal: response.data.filesDimensionsOriginal[filesDimensionsOriginalKeys[i]],
                                key: uploadedImages.length + 1,
                                url: params.uploaderUrl + "/visitor/filename/" + params.sessionId + "/thumbs/" + response.data.files[i]
                            });
                        }
                        /* Set uploaded images */
                        dispatch(setUploadedImage(uploadedImages));

                    }).catch(() => {
                        console.log("There was error during getting files!")
                    });


                }).catch(() => {
                    console.log("There was error during getting files!")
                });

            } else {
                let response = JSON.parse(params.dataAfterImgAdded);
                let uploadedImages = [];
                let filesDimensionsOriginalKeys = response.filesDimensionsOriginal;
                for (let i = 0; i < response.files.length; i++) {
                    uploadedImages.push({
                        filesDimensions: response.filesDimensions[i],
                        filesDimensionsOriginal: response.filesDimensionsOriginal[filesDimensionsOriginalKeys[i]],
                        key: uploadedImages.length + 1,
                        url: params.uploaderUrl + "/uploads/admin/thumbs/" + response.files[i]
                    });
                }

                filesDimensionsOriginalKeys = response.filesDimensionsOriginal;
                for (let i = 0; i < response.files.length; i++) {
                    uploadedImages.push({
                        filesDimensions: response.filesDimensions[i],
                        filesDimensionsOriginal: response.filesDimensionsOriginal[filesDimensionsOriginalKeys[i]],
                        key: uploadedImages.length + 1,
                        url: params.uploaderUrl + "/visitor/filename/" + params.sessionId + "/thumbs/" + response.files[i]
                    });
                }

                dispatch(setUploadedImage(uploadedImages));
            }

        } else {
            if (params.dataAfterImgAdded === undefined) {
                /* AJAX call to get images list */
                axios.get(params.uploaderUrl + "/visitor/filelist/" + params.sessionId).then((response) => {
                    /* Final array with all uploaded images */
                    let uploadedImages = [];
                    let filesDimensionsOriginalKeys = Object.keys(response.data.filesDimensionsOriginal);
                    for (let i = 0; i < response.data.files.length; i++) {
                        uploadedImages.push({
                            filesDimensions: response.data.filesDimensions[i],
                            filesDimensionsOriginal: response.data.filesDimensionsOriginal[filesDimensionsOriginalKeys[i]],
                            key: uploadedImages.length + 1,
                            url: params.uploaderUrl + "/visitor/filename/" + params.sessionId + "/thumbs/" + response.data.files[i]
                        });
                    }
                    /* Set uploaded images */
                    // console.log(uploadedImages)
                    dispatch(setUploadedImage(uploadedImages));

                }).catch(() => {
                    console.log("There was error during getting files!")
                });
            } else {

                let uploadedImages = [];
                let response = JSON.parse(params.dataAfterImgAdded);
                let filesDimensionsOriginalKeys = response.filesDimensionsOriginal;
                for (let i = 0; i < response.files.length; i++) {
                    uploadedImages.push({
                        filesDimensions: response.filesDimensions[i],
                        filesDimensionsOriginal: response.filesDimensionsOriginal[filesDimensionsOriginalKeys[i]],
                        key: uploadedImages.length + 1,
                        url: params.uploaderUrl + "/visitor/filename/" + params.sessionId + "/thumbs/" + response.files[i]
                    });
                }
                // console.log(uploadedImages)
                dispatch(setUploadedImage(uploadedImages));
            }
        }
    }
}

export const setInitParams = (initParams, pages, uploadedImages, activeLayer, storeViews) => {
    //TODO add activeLayer
    const imgArray = pages[0]?.canvasElements.filter((item) => item?.type === 'image' && item.deletable !== false && item.name !== 'undefined');
    return {
        type: actionTypes.SET_INIT_PARAMS,
        uploadedImages,
        pages: pages,
        mode: initParams.mode,
        magentoUrl: initParams.magentoUrl,
        uploaderUrl: initParams.uploaderUrl,
        previewUrl: initParams.previewUrl,
        sessionId: initParams.sessionId,
        designId: initParams.designId,
        productId: initParams.productId,
        layerId: activeLayer,
        updateId: initParams.updateId,
        storeId: initParams.storeId,
        storeViews: storeViews,
        canvasImage: imgArray && imgArray.length === 1,
    }
};

export const showGrid = (showGrid) => {
    return {
        type: actionTypes.SHOW_GRID,
        showGrid
    }
};

export const showGuideline = (showGuideline) => {
    return {
        type: actionTypes.SHOW_GUIDELINE,
        showGuideline
    }
};

export const addElementToPage = (canvasElement) => {
    return {
        type: actionTypes.ADD_ELEMENT_TO_PAGE,
        canvasElement: canvasElement
    }
};

export const setBorderLimitEditingStatus = (data) => {
    return {
        type: actionTypes.SET_BORDER_LIMIT_EDITING_STATUS,
        isBorderLimitEditing: data.isBorderLimitEditing
    }
};

export const setCustomMaskEditingStatus = (data) => {
    return {
        type: actionTypes.SET_CUSTOM_MASK_EDITING_STATUS,
        isCustomMaskEditing: data.isCustomMaskEditing,

    }
};

export const setActiveElement = (selectedElement) => {
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        selectedElement: selectedElement
    }
}

export const setSelectedElements = (selectedElements) => {
    return {
        type: actionTypes.SET_SELECTED_ELEMENTS,
        selectedElements: selectedElements
    }
}

export const setElementLabels = (elementLabels) => {
    return {
        type: actionTypes.SET_ELEMENT_LABELS,
        selectedElementLabels: elementLabels
    }
}

export const setElementStoreViewValues = (elementStoreViewValues) => {
    return {
        type: actionTypes.SET_ELEMENT_STORE_VIEW_VALUES,
        selectedElementStoreViewValues: elementStoreViewValues
    }
}

export const setActivePage = (activePage) => {
    return {
        type: actionTypes.SET_ACTIVE_LAYER,
        activePage
    }
}

export const selectCanvasElement = (selectedElement) => {
    return {
        type: actionTypes.SET_ACTIVE_ELEMENT,
        selectedElement: selectedElement
    }
}

export const getActiveElement = () => {
    return {
        type: actionTypes.GET_ACTIVE_ELEMENT
    }
}

export const setUploadedImage = (uploadedImages) => {
    return {
        type: actionTypes.SET_UPLOADED_IMAGES,
        uploadedImages: uploadedImages
    }
}

export const updateStatusText = (statusText) => {
    return {
        type: actionTypes.UPDATE_STATUS_TEXT,
        statusText: statusText
    }
}

export const saveConfiguration = (data) => {
    return dispatch => {
        /* Update status */
        dispatch(updateStatusText('Saving...'));

        /* Read canvas data */
        let stage = data.stage;
        let canvasElements = [];
        let stageElements = stage.children[0].children;

        for (let i = 0; i < stageElements.length; i++) {
            let element = stageElements[i];
            console.log('element.attrs', element.attrs.user_adminAccessOnly)
            if (element.attrs.name !== 'background' && element.constructor.name !== 'Transformer') {
                switch (element.attrs.type) {
                    case 'square':
                        canvasElements.push({
                            ...commonProperties(element.attrs),
                            fill: element.attrs.fill,
                            width: element.attrs.width,
                            height: element.attrs.height,
                            layer_allocation_data: element.attrs.layer_allocation_data,
                            user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                        });
                        break;
                    case 'circle':
                        canvasElements.push({
                            ...commonProperties(element.attrs),
                            fill: element.attrs.fill,
                            radius: element.attrs.radius,
                            layer_allocation_data: element.attrs.layer_allocation_data,
                            user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                        });
                        break;
                    case 'triangle':
                        canvasElements.push({
                            ...commonProperties(element.attrs),
                            fill: element.attrs.fill,
                            radius: element.attrs.radius,
                            sides: element.attrs.sides,
                            layer_allocation_data: element.attrs.layer_allocation_data,
                            user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                        });
                        break;
                    case 'line':
                        canvasElements.push({
                            ...commonProperties(element.attrs),
                            tension: element.attrs.tension,
                            fill: element.attrs.stroke,
                            points: element.attrs.points,
                            layer_allocation_data: element.attrs.layer_allocation_data,
                            user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                        });
                        break;
                    case 'text':
                        canvasElements.push({
                            ...commonProperties(element.attrs),
                            user_textTransform: element.attrs.user_textTransform || { value: 'none', label: 'None' },
                            fill: element.attrs.fill,
                            fontSize: element.attrs.fontSize,
                            fontStyle: element.attrs.fontStyle,
                            fontFamily: element.attrs.fontFamily,
                            flipX: element.attrs.flipX || false,
                            flipY: element.attrs.flipY || false,
                            letterSpacing: element.attrs.letterSpacing || 0,
                            textLimit: element.attrs.textLimit,
                            fontStrokeWidth: element.attrs.strokeWidth,
                            colorFontStroke: element.attrs.stroke,
                            textMaxWidth: element.attrs.textMaxWidth,
                            align: element.attrs.align,
                            text: encodeURI(element.attrs.text),
                            shadowColor: element.attrs.shadowColor,
                            shadowEnabled: element.attrs.shadowEnabled,
                            elementId: element.attrs.elementId,
                            layer_allocation_data: element.attrs.layer_allocation_data,
                            user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                            // changePosition: element.attrs.changePosition ? element.attrs.changePosition : (element.changeLocalPosition || false),
                            changePosition: element.attrs.changePosition ? (element.changeLocalPosition) : element.attrs.changePosition,
                        });
                        break;
                    case 'icon':
                        canvasElements.push({
                            ...commonProperties(element.attrs),
                            width: element.attrs.width,
                            height: element.attrs.height,
                            image: element.attrs.image.src,
                            cropHeight: element.attrs.cropHeight || 0,
                            cropWidth: element.attrs.cropWidth || 0,
                            cropX: element.attrs.cropX || 0,
                            cropY: element.attrs.cropY || 0,
                            newBoxX: element.attrs.newBoxX || 0,
                            newBoxY: element.attrs.newBoxY || 0,
                            newBoxWidth: element.attrs.newBoxWidth || 0,
                            newBoxHeight: element.attrs.newBoxHeight || 0,
                            layer_allocation_data: element.attrs.layer_allocation_data,
                            user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                        });
                        break;
                    case 'image':

                        canvasElements.push({
                            ...commonProperties(element.attrs),
                            bgColor: element.attrs.fill,
                            smallBackground_image: element.attrs.smallBackground_image,
                            height: element.attrs.height * (element?.attrs?.scaleY ?? 1),
                            width: element.attrs.width * (element?.attrs?.scaleX ?? 1),
                            naturalWidth: element.attrs.naturalWidth,
                            naturalHeight: element.attrs.naturalHeight,
                            imageWithoutBg: element.attrs.imageWithoutBg,
                            scaleX: 1,
                            scaleY: 1,
                            user_isShapeHeart: element.attrs.user_isShapeHeart,
                            image: element.attrs?.image?.src,
                            widthDiff: element.attrs.widthDiff || 0,
                            heightDiff: element.attrs.heightDiff || 0,
                            cropHeight: element.attrs.cropHeight || 0,
                            cropWidth: element.attrs.cropWidth || 0,
                            cropX: element.attrs.cropX || 0,
                            cropY: element.attrs.cropY || 0,
                            newBoxX: element.attrs.newBoxX || 0,
                            newBoxY: element.attrs.newBoxY || 0,
                            newBoxWidth: element.attrs.newBoxWidth || 0,
                            newBoxHeight: element.attrs.newBoxHeight || 0,
                            elementRepeat: element.attrs.elementRepeat || false,
                            elementRepeatOval: element.attrs.elementRepeatOval || false,
                            faceId: element.attrs.faceId,
                            faceCropped: element.attrs?.faceCropped,
                            layer_allocation_data: element.attrs.layer_allocation_data,
                            user_removeFromWorkspace: element.attrs.user_removeFromWorkspace,
                        });
                        break;
                    default:
                        break;
                }
            }
        }

        /* Read canvas elements and details about size and position */
        const {
            toolImagesAllowed,
            toolShapesAllowed,
            toolEmojiAllowed,
            toolTextAllowed,
            propertyTextStrokeAllowed,
            propertyTextStrokeColorAllowed,
            propertyTextShadowColorAllowed,
            propertyTextShadowAllowed,
            propertyTextColorAllowed,
            propertyTextShowAllowed,
            propertyTextFontAllowed,
            propertyFaceCropSizeAllowed,
            propertyFaceCropZoomAllowed,
            propertyFaceCropRotateAllowed,
            isMergedTemplate,
            newTemplateRatio
        } = data;
        console.log("canvasElements", canvasElements)
        let config = {
            widthRatio: newTemplateRatio.widthRatio,
            heightRatio: newTemplateRatio.heightRatio,
            session_id: data.sessionId,
            layer_id: data.layerId,
            design_id: data.designId,
            width: stage.attrs.width,
            height: stage.attrs.height,
            canvasElements: canvasElements,
            product_id: data.productId,
            store_id: data.storeId,
            printFile: data.printFile,
            previewFile: data.previewFile,
            borderLimit: data.borderLimit,
            canvasMaskElements: data.canvasMaskElements,
            toolImagesAllowed,
            toolShapesAllowed,
            toolEmojiAllowed,
            toolTextAllowed,
            propertyTextStrokeAllowed,
            propertyTextStrokeColorAllowed,
            propertyTextShadowColorAllowed,
            propertyTextShadowAllowed,
            propertyTextColorAllowed,
            propertyTextShowAllowed,
            propertyTextFontAllowed,
            propertyFaceCropSizeAllowed,
            propertyFaceCropZoomAllowed,
            propertyFaceCropRotateAllowed,
            isMergedTemplate,
        };
        // console.log("config", config);
        if (data.mode === 'PRODUCTION') {
            window.parent.postMessage({ datatype: "addtocart", success: 1, config: JSON.stringify(config) }, "*");
            setTimeout(() => {
                dispatch(updateStatusText(''));
            }, 1000);
        } else {
            /* Save configuration */
            const req = new XMLHttpRequest();

            req.onreadystatechange = function () {
                if (req.readyState === 4 && req.status === 200) {
                    dispatch(updateStatusText('Saved'));
                    setTimeout(() => {
                        dispatch(updateStatusText(''));
                    }, 1000);
                    setTimeout(() => {
                        window.parent.postMessage({ datatype: "addtocart", success: 1 }, "*");
                    }, 1000);
                }
            };
            let date = new Date();
            req.open("PUT", data.magentoUrl + 'productsdesigner/config/save?t=' + date.getTime());
            req.send(JSON.stringify(config));

        }

    }

};
