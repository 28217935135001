import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions/index';
import "./Menu.scss";
import DeLogo from "../../assets/images/de_logo.png"
import FrLogo from "../../assets/images/fr_logo.png"
import ItLogo from "../../assets/images/it_logo.png"
import fireboxLogo from "../../assets/images/firebox_logo.png"
import rotate_left from "../../assets/images/left-icon.png"
import rotate_right from "../../assets/images/right-icon.png"

import zoom_in from "../../assets/images/zoom_in_icon 1.png"
import zoom_out from "../../assets/images/zoom_in_icon 2.png"

import Translate from "react-translate-component";
import queryString from "query-string";
import close_btn from '../../assets/images/iconfinder_Close_6351931 2.png'
import { PREVIEW_SIZE, MAX_IMAGE_SCALE } from "../../config";
import TranslateForAlert from "../../helpes/translateForAlert";
import { Button } from "shards-react";

import {
    DESKTOP_WIDTH,
} from "../../config";


const axios = require("axios");
const params = queryString.parse(window.location.search);

class Menu extends Component {

    state = {
        curTime: new Date().toLocaleString(),
        showSettingSubmenu: false,
        showHelpSubmenu: false,
        currentStatus: 'idle',
        statusText: '',
        buttonSuffix: ''
    };

    componentDidMount() {
        setTimeout(() => {
            this.props.setItemDownloadingStatus({
                isItemDownloading: -1
            });
        }, 5000)
    }


    checkForNotChangedImages() {
        let areAllImagesChanged = this.props.pages[0].canvasElements.find((i) => {
            console.log(i)
            return i?.type === 'image' && i?.unselectable === false && i?.isThisElemEditedByUser === false && i.name !== "Background2" && i.name !== "Background"
        })
        if (areAllImagesChanged) {
            if (params.magento_url !== undefined && params.magento_url.includes('firebox') && queryString.parse(window.location.search).lang == 'en') {
                TranslateForAlert("Oops, didn't you forget something for firebox?")
            } else {
                TranslateForAlert("Oops, didn't you forget something?")
            }

            this.props.onSelectElement(areAllImagesChanged.name);
            this.props.onShowUploadForm();
        } else {
            setTimeout(() => {
                this.props.showOrderPreviewPopup()
            }, 50)
        }
    }

    resetSubmenus = () => {
        this.setState({ showSettingSubmenu: false, showHelpSubmenu: false });
    };

    resetChanges = (e) => {
        e.stopPropagation();
        e.preventDefault();
        this.resetSubmenus();
        window.parent.postMessage({ eventType: "RESET_CHANGES" }, '*');
    }

    showSettingSubmenu = () => {
        this.resetSubmenus();
        this.setState({ showSettingSubmenu: ((!this.state.showSettingSubmenu)) });
    };

    checkBorderLimits() {
        let
            layout = this.props.pages[this.props.activePage],
            stage = this.props.stage,
            {
                x = layout.borderLimit.x,
                y = layout.borderLimit.y,
                width = layout.borderLimit.width,
                height = layout.borderLimit.height,
            } = this.props.isBorderLimitEditing,
            notFitElements = [];

        if (layout.canvasElements?.length > 0 && stage) {
            stage.children[this.props.activePage].children.map((el) => {
                let elX = Math.round(el.x() - ((el.attrs.offsetX * (el.attrs.scaleX)) || 0));
                let elY = Math.round(el.y() - (el.attrs.offsetY * (el.attrs.scaleY)) || 0);
                let isElementFit =
                    (elX >= Math.round(x)) && (elY >= Math.round(y)) &&
                    (elX + el.width() * (el.attrs.scaleX ?? 1)) <= (width + Math.round(x)) &&
                    (elY + el.height() * (el.attrs.scaleY ?? 1)) <= (height + Math.round(y))

                if (!isElementFit && el.attrs.name !== 'background' && el.constructor.name !== 'Transformer') {
                    notFitElements.push(el)
                }
                return null;
            })


            let note, lang = queryString.parse(window.location.search).lang;
            switch (lang) {
                case "nl":
                    note = "Du har placeret et objekt uden for det printbare område. Bekræft venligst, at du er indforstået med, at det kan printes delvist eller ukorrekt."
                    break;
                case "en":
                    note = "You placed an element outside the printable area. Please confirm, that you understood that this will not be completely or correctly printed"
                    break;
                case "it":
                    note = "Hai posizionato un elemento al di fuori della superficie di stampa. Per favore, conferma di aver compreso che tale elemento non sarà stampato completamente o correttamente"
                    break;
                case "fr":
                    note = "Vous avez placé un élément en dehors de la zone imprimable. Veuillez confirmer que vous comprenez que cela ne sera pas imprimé complètement ou correctement."
                    break;
                case "dk":
                    note = "Du har placeret et objekt uden for det printbare område. Bekræft venligst, at du er indforstået med, at det kan printes delvist eller ukorrekt."
                    break;
                default:
                    note = "Du hast ein Element außerhalb des druckbaren Bereiches platziert. Bitte bestätige, dass du verstanden hast, dass dies nicht vollständig bzw. korrekt abgedruckt wird."
                    break;
            }

            return notFitElements.length > 1 ? window.confirm(note) : true
        }
        return true
    }

    getSettingSubmenu = () => {
        return (
            <div className="designer-menu-submenu">
                <div className="submenu-item"
                    onClick={this.props.switchGrid.bind(this, !this.props.showGrid)}>
                    <Translate content={"gridlines"} />
                </div>
                <div className="submenu-item"
                    onClick={(e) => this.resetChanges(e)}>
                    <Translate content={"Reset changes"} />
                </div>
                {this.props.mode === "ADMIN" && this.props.pages[this.props.activePage].borderLimit?.width > 0 ?
                    <div className="submenu-item"
                        onClick={() => this.props.setBorderLimitEditingStatus({
                            isBorderLimitEditing: {
                                ...this.props.isBorderLimitEditing,
                                status: !this.props.isBorderLimitEditing?.status
                            }
                        })}>
                        <span>Border limit</span>
                    </div>
                    : null}
                {this.props.mode === "ADMIN" ?
                    <div className="submenu-item"

                        onClick={() => {
                            this.props.onShowShapesForm()
                            this.props.setCustomMaskEditingStatus({
                                isCustomMaskEditing: {
                                    ...this.props.isCustomMaskEditing,
                                    status: !this.props.isCustomMaskEditing?.status
                                }
                            })
                        }}>

                        <span>Product Mask</span>
                    </div>
                    : null}

                {/* MultiView Template */}
                {this.props.mode === "ADMIN" && this.props.pages[this.props.activePage]?.multipartTemplate == 1 ?
                    <div className="submenu-item"
                        onClick={() => {
                            this.props.onShowShapesForm()
                            this.props.setCustomMaskEditingStatus({
                                isCustomMaskEditing: {
                                    ...this.props.isCustomMaskEditing,
                                    status: !this.props.isCustomMaskEditing?.status
                                }
                            })
                        }}>
                        <span>MultiView</span>
                    </div>
                    : null}

            </div>
        );
    };

    saveDesigner = () => {
        console.log("SAVE DESIGNER CALLED!!")
        if (this.props.mode !== 'PRODUCTION') {
            let layout = this.props.pages[this.props.activePage],
                {
                    x = layout?.borderLimit?.x,
                    y = layout?.borderLimit?.y,
                    width = layout?.borderLimit?.width * (layout?.borderLimit?.scaleX ?? 1),
                    height = layout?.borderLimit?.height * (layout?.borderLimit?.scaleY ?? 1),
                } = this.props?.isBorderLimitEditing;

            let stageCopy = this.props.stage.clone();
            stageCopy.getChildren()[0].children.forEach((child) => {
                if (child?.attrs?.user_removeFromPreview !== undefined && child.attrs.user_removeFromPreview) {
                    // stageCopy.find("." + child.attrs.name).remove();
                    stageCopy.find("." + child.attrs.name).destroy();
                }
            });
            this.props.onSaveLayer({
                newTemplateRatio: {
                    widthRatio: this.props?.pages[this.props?.activePage]?.newTemplateRatio?.widthRatio ?? 1,
                    heightRatio: this.props?.pages[this.props?.activePage]?.newTemplateRatio?.heightRatio ?? 1
                },
                borderLimit: { x, y, width, height, status: false },
                stage: this.props.stage,
                magentoUrl: this.props.magentoUrl,
                designId: this.props.designId,
                statusText: 'Saving...',
                mode: this.props.mode,
                canvasMaskElements: JSON.stringify(this.props.canvasMaskElements),
                sessionId: this.props.sessionId,
                productId: this.props.productId,
                layerId: this.props.pages[this.props.activePage]?.baseLayer_id || this.props.layerId,
                toolTextAllowed: this.props.toolTextAllowed,
                toolEmojiAllowed: this.props.toolEmojiAllowed,
                toolShapesAllowed: this.props.toolShapesAllowed,
                toolImagesAllowed: this.props.toolImagesAllowed,
                propertyTextStrokeAllowed: this.props.propertyTextStrokeAllowed,
                propertyTextStrokeColorAllowed: this.props.propertyTextStrokeColorAllowed,
                propertyTextShadowColorAllowed: this.props.propertyTextShadowColorAllowed,
                propertyTextShadowAllowed: this.props.propertyTextShadowAllowed,
                propertyTextColorAllowed: this.props.propertyTextColorAllowed,
                propertyTextShowAllowed: this.props.propertyTextShowAllowed,
                propertyTextFontAllowed: this.props.propertyTextFontAllowed,
                propertyFaceCropSizeAllowed: this.props.propertyFaceCropSizeAllowed,
                propertyFaceCropZoomAllowed: this.props.propertyFaceCropZoomAllowed,
                propertyFaceCropRotateAllowed: this.props.propertyFaceCropRotateAllowed,
            });

        } else {
            if (queryString.parse(window.location.search).admin_edit !== undefined) {
                /* Generate filename from data */
                let filename = queryString.parse(window.location.search).filename;
                // console.log(filename)
                let stageCopy = this.props.stage.clone();

                /* Get print image size */
                let templateInfo = this.props.pages[this.props.activePage];

                /* Calculate image ratios */
                let templateWidth = parseInt(templateInfo.width);
                let stageWidth = parseInt(stageCopy.attrs.width);
                let pixelRatio = parseFloat(templateWidth / stageWidth);
                let previewRatio = parseFloat(PREVIEW_SIZE / stageWidth);

                /* Remove transformer from preview file */
                stageCopy.removeName('Transformer');
                let needToDelete = [];
                stageCopy.getChildren()[0].children.forEach(function (child) {
                    if (child.attrs.user_removeFromPreview !== undefined && child.attrs?.user_removeFromPreview) {
                        needToDelete.push(child);
                    }
                });
                // needToDelete.forEach(child => child.remove())
                needToDelete.forEach(child => child.destroy())
                let previewImage = stageCopy.toDataURL({ pixelRatio: previewRatio });

                /* Remove transformer from final print file */
                let printCopy = this.props.stage.clone();

                needToDelete = [];
                printCopy.getChildren()[0].children.forEach(function (child) {
                    if (child.constructor.name === 'Transformer') needToDelete.push(child);
                    if (child.attrs.user_removeFromPrint !== undefined) {
                        if (child.attrs?.user_removeFromPrint) {
                            needToDelete.push(child);
                        }
                    }
                });
                // needToDelete.forEach(child => child.remove())
                needToDelete.forEach(child => child.destroy())
                let printImage = "";
                // let png_output = localStorage.getItem("png_output");

                printImage = this.props.pages[this.props.activePage].png_output === 1 ?
                    printCopy.toDataURL({ pixelRatio: pixelRatio, mimeType: "image/png" }) :
                    printCopy.toDataURL({ pixelRatio: pixelRatio, mimeType: "image/jpeg" })
                console.log(printImage, "printImage")
                
                // let printImage = printCopy.toDataURL({pixelRatio: pixelRatio, mimeType: "image/png"});
                // console.log(printImage, "printImage")

                /* Prepare files for save request */
                let formData = new FormData();
                let config = { headers: { 'content-type': 'multipart/form-data' } };
                formData.append('filename', filename);
                formData.append('print_image', printImage);
                formData.append('preview_image', previewImage);
                if (parseInt(params.robot)) {
                    var self = this;
                    axios.post(this.props.magentoUrl + "scripts/upload_files.php", formData, config).then(() => {
                        self.setState({ buttonSuffix: " - " + self.state.curTime });
                    }).catch(function (error) {
                        console.log(error);
                    });
                } else {
                    let ifNeedSave = window.confirm("Are you sure you want to change the design in the order?");

                    /* Send files save request */
                    if (ifNeedSave) {
                        axios.post(this.props.magentoUrl + "scripts/upload_files.php", formData, config).then(() => {
                            // handle success
                            alert("Design edited");
                        }).catch(function (error) {
                            // handle error
                            console.log(error);
                        });
                    }
                }
            } else {
                let quickEditElements = {};
                let stageChildren = this.props.stage.children;
                let stageElements = Object.values(stageChildren)[0];
                let stageElementsChildren = stageElements.children;
                Object.keys(stageElementsChildren).forEach(function (key) {
                    let elem = stageElementsChildren[key];
                    if (elem.attrs !== undefined) {
                        if (elem.attrs.quickEdit) {
                            if (elem.attrs.type === 'image') {
                                quickEditElements[elem?.attrs?.name] = {
                                    name: elem?.attrs?.name,
                                    type: elem?.attrs?.type,
                                    img: elem?.attrs?.image?.src,
                                    fill: elem?.attrs?.fill
                                }
                            } else if (elem.attrs.type === 'text') {
                                quickEditElements[elem?.attrs?.name] = {
                                    name: elem?.attrs?.name,
                                    type: elem?.attrs?.type,
                                    text: elem?.attrs?.text,
                                    fill: elem?.attrs?.fill
                                }
                            } else if (elem.attrs.type === 'shape') {
                                // For future :-)
                            }
                        }
                    }
                });

                window.parent.postMessage({
                    datatype: "saveimage",
                    canvasElements: JSON.stringify({ canvasElements: quickEditElements })
                }, "*");
            }
        }
        this.props.onSave();
    }

    closeWindow = () => {
        window.close();
    };

    onSave() {
        console.log("OnSave Called!!")

        // if (!this.checkBorderLimits()) return

        let allImagesHasGoodQuality = true;
        this.props.pages[this.props.activePage].canvasElements.forEach((item) => {

            if (item?.type === 'image') {

                const { width, height, naturalWidth, naturalHeight } = item;

                if (width / naturalWidth > MAX_IMAGE_SCALE || height / naturalHeight > MAX_IMAGE_SCALE) {
                    TranslateForAlert("Your image is too small")
                    allImagesHasGoodQuality = false;
                }
            }
        });

        if (allImagesHasGoodQuality) {
            setTimeout(() => {
                let layout = this.props.pages[this.props.activePage],
                    {
                        x = layout?.borderLimit?.x,
                        y = layout?.borderLimit?.y,
                        width = layout?.borderLimit?.width,
                        height = layout?.borderLimit?.height,

                    } = this.props?.isBorderLimitEditing;

                // console.log("this.props?.isBorderLimitEditing", this.props?.isBorderLimitEditing)
                this.props.onSaveLayer({
                    newTemplateRatio: {
                        widthRatio: this.props?.pages[this.props?.activePage]?.newTemplateRatio?.widthRatio ?? 1,
                        heightRatio: this.props?.pages[this.props?.activePage]?.newTemplateRatio?.heightRatio ?? 1
                    },
                    borderLimit: { x, y, width, height, status: false },
                    stage: this.props.stage,
                    canvasMaskElements: JSON.stringify(this.props.canvasMaskElements),
                    magentoUrl: this.props.magentoUrl,
                    designId: this.props.designId,
                    statusText: 'Saving...',
                    mode: this.props.mode,
                    sessionId: this.props.sessionId,
                    productId: this.props.productId,
                    layerId: this.props.pages[this.props.activePage]?.baseLayer_id || this.props.layerId,
                    toolTextAllowed: this.props.toolTextAllowed,
                    toolEmojiAllowed: this.props.toolEmojiAllowed,
                    toolShapesAllowed: this.props.toolShapesAllowed,
                    toolImagesAllowed: this.props.toolImagesAllowed,
                    propertyTextStrokeAllowed: this.props.propertyTextStrokeAllowed,
                    propertyTextStrokeColorAllowed: this.props.propertyTextStrokeColorAllowed,
                    propertyTextShadowColorAllowed: this.props.propertyTextShadowColorAllowed,
                    propertyTextShadowAllowed: this.props.propertyTextShadowAllowed,
                    propertyTextColorAllowed: this.props.propertyTextColorAllowed,
                    propertyTextShowAllowed: this.props.propertyTextShowAllowed,
                    propertyTextFontAllowed: this.props.propertyTextFontAllowed,
                    propertyFaceCropSizeAllowed: this.props.propertyFaceCropSizeAllowed,
                    propertyFaceCropZoomAllowed: this.props.propertyFaceCropZoomAllowed,
                    propertyFaceCropRotateAllowed: this.props.propertyFaceCropRotateAllowed,
                    isMergedTemplate: this.props.pages[this.props.activePage].isMergedTemplate,
                });

            }, 0)
        }
        this.props.onSave();
    }

    historyStep(step) {
        this.props.onEditElement({
            historyStep: step,
            // element: this.props.activeElement.attrs.name,
            activePage: this.props.activePage,
        });
    }

    render() {
        let Logo = null;
        switch (params.lang) {
            case 'fr': {
                Logo = FrLogo;
                break;
            }
            case 'it': {
                Logo = ItLogo;
                break;
            }
            default: {
                Logo = DeLogo;
                break;
            }
        }

        if (params.magento_url !== undefined && params.magento_url.includes('firebox')) {
            Logo = fireboxLogo;
        }
        let newEditorTitle = "";
        if (this.props.stepname == "bgdesign") {
            newEditorTitle = <Translate content={"Design_anpassen_lang"} />;
        } else if (this.props.stepname == "bgcolor") {
            newEditorTitle = <Translate content={"Hintergundfarbe_anpassen_lang"} />;
        } else if (this.props.stepname == "textspopupCommon") {
            newEditorTitle = <Translate content={"texts_lang"} />;
        } else if (this.props.stepname == "mehrCommon") {
            newEditorTitle = <Translate content={"Mehr_anpassen_lang"} />;
        }

        return (
            <>
                {this.props?.pages[this.props.activePage]?.commonEditorForAll == 0 ?
                    <div className={"designer-menu-wrap"}>

                        <div className="designer-menu">

                            <div className="designer-menu-item left">
                                {this.props.historyActiveStep >= 1 || this.props.historyElementsCopy?.length - 1 ?
                                    <div className="designer-menu-item__rotation back_ahead_btn">
                                        {this.props.historyActiveStep >= 1 ? <img src={rotate_left} alt="rotate_left"
                                            onClick={this.historyStep.bind(this, -1)} /> : null}
                                        {this.props.historyElementsCopy?.length - 1 > this.props.historyActiveStep ?
                                            <img src={rotate_right} alt="rotate_right"
                                                onClick={this.historyStep.bind(this, 1)} /> : null}
                                    </div> : null}

                                <div className="designer-menu-item__zoom">
                                    {this.props.canvasScale < 200 ? <img src={zoom_out} alt="" onClick={() => {
                                        this.props.onScaleChangeValue(1)
                                    }} /> : null}
                                    {/*{Math.round((this.props.STAGE_SIZE / MIN_STAGE_SIZE) * 100) + "%"}*/}
                                    {this.props.canvasScale > 100 ? <img src={zoom_in} alt="" onClick={() => {
                                        this.props.onScaleChangeValue(-1)
                                    }} /> : null}
                                </div>
                                {
                                    this.props.pages.length > 0 && this.props.mode === "ADMIN" ? (
                                        <div className="designer-menu-item" style={{ margingLeft: "10px" }}>
                                            {this.state.showSettingSubmenu ?
                                                <div
                                                    className="text-form-popup-overlay text-form-popup-overlay--transparent"
                                                    onClick={this.resetSubmenus} />
                                                : null}
                                            <Button outline
                                                theme="light"
                                                className={'menu-item' + ((this.state.showSettingSubmenu) ? ' active' : '')}
                                                onClick={this.showSettingSubmenu}>
                                                <Translate content={"settings"} />
                                            </Button>
                                            {((this.state.showSettingSubmenu) ? this.getSettingSubmenu() : null)}
                                        </div>) : null
                                }
                            </div>
                            <div className={"designer-menu-logo"}>
                                <img src={Logo} alt="" />
                            </div>
                            <div className="designer-menu-item right">
                                {
                                    window.innerWidth > 616 ? <>
                                        {this.props.pages[this.props.activePage]?.renderers.length === 0 ?
                                            null
                                            :
                                            <div className="designer-menu-item designer-menu-item_preview">
                                                <button onClick={this.props.showMobilePreview} className={'secondary-button cust_font_change'}>
                                                    <Translate content={"Preview"} />
                                                </button>
                                            </div>
                                        }

                                        {this.props.statusText?.length > 0 ?
                                            <div className="canvas-status" id="canvas-status">
                                                {this.props.statusText}
                                            </div>
                                            :
                                            (this.props.mode === 'PRODUCTION' && parseInt(params.robot) !== 1) ?
                                                <Button className="generateprintfile cust_font_change" theme="dark"
                                                    onClick={() => {
                                                        this.props.resetCanvasZoom()

                                                        this.checkForNotChangedImages()

                                                    }}>
                                                    <Translate content={"done"} />
                                                </Button>
                                                :
                                                <>
                                                    {this.props.pages.length > 0 ? (
                                                        <>
                                                            <Button theme="dark generateprintfile cust_font_change"
                                                                onClick={this.saveDesigner.bind(this)}
                                                                style={{ marginRight: 10 }}
                                                            >
                                                                <Translate content={"done"} />
                                                                {this.state.buttonSuffix}
                                                            </Button>
                                                        </>
                                                    ) : null}
                                                </>
                                        }
                                    </> : null
                                }


                                {parseInt(params.robot) === 1 ? <span id="robotStatus">
                                    {this.props.isItemDownloading > 0 || this.props.noImgLoadError === false ? 'Loading' : 'Ready to print'}
                                </span> : null}

                                {parseInt(params.robot) === 1 ? null :
                                    <div className="closeEditorBtn" onClick={() => {
                                        this.props.hidePopup()
                                        window.parent.postMessage({ datatype: "hide_designer" }, "*")
                                        window.onbeforeunload = null;
                                    }}>
                                        <img src={close_btn} alt="" />
                                    </div>}
                            </div>
                        </div>
                    </div>
                    :
                    <div>
                        <div className="designer-menu">
                            <div className="designer-menu-item left">

                            </div>
                            <div className={"designer-menu-logo"} style={{ "fontWeight": "bold" }}>
                                {newEditorTitle}
                            </div>
                            <div className="designer-menu-item right">
                                <div className="closeEditorBtn" onClick={() => {
                                    window.parent.postMessage({ datatype: "hide_designer" }, "*")
                                    window.onbeforeunload = null;
                                }}>
                                    <img src={close_btn} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                }

                {/*<div className="designer-subMenu">*/}
                {/*    <div className="designer-subMenuWrap">*/}
                {/*        <div className="designer-subMenu__history">*/}
                {/*            {*/}
                {/*                this.props.historyActiveStep >= 1 ?*/}
                {/*                    <Button*/}
                {/*                        className={"designer-subMenu__historyBtn"}*/}
                {/*                        outline*/}
                {/*                        theme="light"*/}
                {/*                        size="sm"*/}
                {/*                        onClick={this.historyStep.bind(this, -1)}>*/}
                {/*                        <img className={"designer-subMenu__historyBtnImg"}*/}
                {/*                             style={{transform: 'scaleY(-1) scaleX(1)'}} src={RotateIcon} alt=""/>*/}
                {/*                        <Translate content={"Revert"}/>*/}
                {/*                    </Button>*/}
                {/*                    : null*/}
                {/*            }*/}
                {/*            {*/}
                {/*                this.props.historyElementsCopy?.length - 1 > this.props.historyActiveStep ?*/}
                {/*                    <Button*/}
                {/*                        className={"designer-subMenu__historyBtn"}*/}
                {/*                        outline*/}
                {/*                        theme="light"*/}
                {/*                        size="sm"*/}
                {/*                        onClick={this.historyStep.bind(this, 1)}>*/}
                {/*                        <img className={"designer-subMenu__historyBtnImg"}*/}
                {/*                             style={{transform: 'scaleY(-1) scaleX(-1)'}} src={RotateIcon1} alt=""/>*/}
                {/*                        <Translate content={"Retry"}/>*/}
                {/*                    </Button>*/}
                {/*                    : null*/}
                {/*            }*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </>
        );
    }
}

const mapStateToProps = state => {
    return {
        activeElement: state.glb.activeElement,
        pages: state.glb.pages,
        historyElementsCopy: state.glb.historyElementsCopy,
        historyActiveStep: state.glb.historyActiveStep,
        activePage: state.glb.activePage,
        designerMode: state.glb.designerMode,
        canvasElements: state.cnv.canvasElements,
        previewUrl: state.glb.previewUrl,
        activeCanvasElement: state.cnv.activeCanvasElement,
        stage: state.glb.stage,
        magentoUrl: state.glb.magentoUrl,
        designId: state.glb.designId,
        statusText: state.glb.statusText,
        mode: state.glb.mode,
        sessionId: state.glb.sessionId,
        productId: state.glb.productId,
        layerId: state.glb.layerId,
        updateId: state.glb.updateId,
        showGrid: state.glb.showGrid,
        showGuideline: state.glb.showGuideline,
        previews: state.prv.previews,
        isBorderLimitEditing: state.glb.isBorderLimitEditing,
        isCustomMaskEditing: state.glb.isCustomMaskEditing,
        toolTextAllowed: state.tol.toolTextAllowed,
        toolEmojiAllowed: state.tol.toolEmojiAllowed,
        toolShapesAllowed: state.tol.toolShapesAllowed,
        toolImagesAllowed: state.tol.toolImagesAllowed,
        propertyTextStrokeAllowed: state.tol.propertyTextStrokeAllowed,
        propertyTextStrokeColorAllowed: state.tol.propertyTextStrokeColorAllowed,
        propertyTextShadowColorAllowed: state.tol.propertyTextShadowColorAllowed,
        propertyTextShadowAllowed: state.tol.propertyTextShadowAllowed,
        propertyTextColorAllowed: state.tol.propertyTextColorAllowed,
        propertyTextShowAllowed: state.tol.propertyTextShowAllowed,
        propertyTextFontAllowed: state.tol.propertyTextFontAllowed,
        propertyFaceCropSizeAllowed: state.tol.propertyFaceCropSizeAllowed,
        propertyFaceCropZoomAllowed: state.tol.propertyFaceCropZoomAllowed,
        propertyFaceCropRotateAllowed: state.tol.propertyFaceCropRotateAllowed,
        canvasMaskElements: state.cnvMask.canvasMaskElements,
        isItemDownloading: state.glb.isItemDownloading,
        noImgLoadError: state.glb.noImgLoadError,
        stepname: state.glb.stepname,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onShowShapesForm: () => {
            // dispatch(actionCreators.setActiveElement(""));
            dispatch(actionCreators.showShapesForm());
        },
        onEditElement: (data) => {
            dispatch(actionCreators.editElementAttrs(data));
        },
        hidePopup: () => dispatch(actionCreators.hidePopup()),
        onShowUploadForm: () => {
            if (window.innerWidth < DESKTOP_WIDTH) document.querySelectorAll("body")[0].style.overflow = 'hidden'
            dispatch(actionCreators.showUploadForm());
        },
        showOrderPreviewPopup: (data) => dispatch(actionCreators.showOrderPreviewPopup()),
        // onSelectElement: (element) => dispatch(actionCreators.setActiveCanvasElement(element)),
        onSelectElement: (element) => dispatch(actionCreators.setActiveElement(element)),
        onSaveLayer: (data) => dispatch(actionCreators.saveConfiguration(data)),
        switchGrid: (showGrid) => dispatch(actionCreators.showGrid(showGrid)),
        setBorderLimitEditingStatus: (data) => dispatch(actionCreators.setBorderLimitEditingStatus(data)),
        setCustomMaskEditingStatus: (data) => dispatch(actionCreators.setCustomMaskEditingStatus(data)),
        setItemDownloadingStatus: (data) => dispatch(actionCreators.setItemDownloadingStatus(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
